@font-face {
  font-family: "montserrat-700";
  src: url("./assets/fonts/montserrat-700.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-regular";
  src: url("./assets/fonts/montserrat-regular.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat-500";
  src: url("./assets/fonts/montserrat-500.ttf") format("truetype");
}
@font-face {
  font-family: "roboto-regular";
  src: url("./assets/fonts/roboto-regular.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
