/* Language */
.na_desk_lang_options_div {
  position: absolute;
  height: 0px;
  margin-top: 50px;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: #ffffff;
  border-radius: 8px;
  opacity: 0;
  z-index: 2000;
}

.na_desk_lang_options_div_active {
  height: unset;
  width: unset;
  box-shadow: 0px 2.77818px 2.77818px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  transition: all 200ms ease-in-out;
  opacity: 1;
}

.na_desk_lang_option {
  flex: 1;
  margin: auto;
}
