@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
canvas{
  height: 15%;
}

.digit {
  display: inline-block;
  padding: 6px 6px 4px;
  border-radius: 3px;
  background: green;
  margin-right: 1px;
  color: white;
  /* color: rgba(171, 171, 171, 1); */
}
.center {
  text-align: center;
  width: 50%;
  margin: 20px auto;
}
.national-emblem{
  height: 10%;
  padding-top: 15px;
}
.paragraph-text{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 550;
  color: rgba(171, 171, 171, 1);
  width: auto;
  font-size: 0.625rem;
  z-index: 1000;
  margin-bottom: 0;
  text-align: center;
  font-size: 12px;
}

@font-face {
  font-family: "montserrat-700";
  src: url(/static/media/montserrat-700.079ca05d.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-regular";
  src: url(/static/media/montserrat-regular.3cd78665.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-500";
  src: url(/static/media/montserrat-500.e2d60bc4.ttf) format("truetype");
}
@font-face {
  font-family: "roboto-regular";
  src: url(/static/media/roboto-regular.a8d6ac03.ttf) format("truetype");
}

.disable {
  color: rgb(197, 197, 197) !important;
  border: none !important;
}

.form-control:focus {
  border-color: #f3be59;
  border: 1px solid #f3be59;
  box-shadow: 0px 0px 4px #f3be59;
}

.btn:focus {
  border-color: #f3be59;
  border: 1px solid #f3be59;
  box-shadow: 0px 0px 4px #f3be59;
}

.form-check-input:checked {
  color: #fff !important;
  border-color: #f3be59 !important;
  background-color: #f3be59 !important;
}

.form-check-input:focus {
  border-color: #f3be59;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(243, 190, 89, 0.25);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #f3be59;
}

.rightTI {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  -webkit-animation: marquee 22s linear infinite;
          animation: marquee 22s linear infinite;
}
.rightTI:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes marquee {
  0% {
    text-indent: 100%;
  }
  100% {
    text-indent: -100%;
  }
}

@keyframes marquee {
  0% {
    text-indent: 100%;
  }
  100% {
    text-indent: -100%;
  }
}

.desk-marquee {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 136.9%;
  color: #ffffff;
}

.header-banner {
  margin-top: -2.5%;
  z-index: 0;
}

.header-top {
  z-index: 1500;
  position: relative;
}

.blinking {
  -webkit-animation: blinkingText 1s infinite;
          animation: blinkingText 1s infinite;
  font-family: futura;
  font-style: italic;
  width: 10%;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 10px;
  background-color: #fc030b;
  border-radius: 10px;
  padding: 4px;
}

@-webkit-keyframes blinkingText {
   
  0% {
      opacity: 0;
  }
  50% {
      opacity: .5;
  }
  100% {
      opacity: 1;
  }
}

@keyframes blinkingText {
   
  0% {
      opacity: 0;
  }
  50% {
      opacity: .5;
  }
  100% {
      opacity: 1;
  }
}

/* Language */
.na_select_lang {
  position: absolute;
  box-shadow: 2px -3px 11px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background: rgba(17, 17, 17, 0.4);
  border: 2px solid #ffba6e;
  border-radius: 9px 9px 0px 0px;
}

.na_select_lang_active {
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.na_lang_options_div {
  /* visibility: hidden; */
  height: 0px;
  margin-bottom: 5px;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: #ffffff;
  border-radius: 8px;
  opacity: 0;
}

.na_lang_options_div_active {
  height: unset;
  width: unset;
  box-shadow: 0px 2.77818px 2.77818px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  transition: all 200ms ease-in-out;
  opacity: 1;
}

.na_lang_options {
  display: flex;
}

.na_lang_option {
  flex: 1 1;
  margin: auto;
}

/* Language */
.na_desk_lang_options_div {
  position: absolute;
  height: 0px;
  margin-top: 50px;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: #ffffff;
  border-radius: 8px;
  opacity: 0;
  z-index: 2000;
}

.na_desk_lang_options_div_active {
  height: unset;
  width: unset;
  box-shadow: 0px 2.77818px 2.77818px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  transition: all 200ms ease-in-out;
  opacity: 1;
}

.na_desk_lang_option {
  flex: 1 1;
  margin: auto;
}

