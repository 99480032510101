body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
canvas{
  height: 15%;
}

.digit {
  display: inline-block;
  padding: 6px 6px 4px;
  border-radius: 3px;
  background: green;
  margin-right: 1px;
  color: white;
  /* color: rgba(171, 171, 171, 1); */
}
.center {
  text-align: center;
  width: 50%;
  margin: 20px auto;
}
.national-emblem{
  height: 10%;
  padding-top: 15px;
}
.paragraph-text{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 550;
  color: rgba(171, 171, 171, 1);
  width: auto;
  font-size: 0.625rem;
  z-index: 1000;
  margin-bottom: 0;
  text-align: center;
  font-size: 12px;
}
