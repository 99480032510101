.disable {
  color: rgb(197, 197, 197) !important;
  border: none !important;
}

.form-control:focus {
  border-color: #f3be59;
  border: 1px solid #f3be59;
  box-shadow: 0px 0px 4px #f3be59;
}

.btn:focus {
  border-color: #f3be59;
  border: 1px solid #f3be59;
  box-shadow: 0px 0px 4px #f3be59;
}

.form-check-input:checked {
  color: #fff !important;
  border-color: #f3be59 !important;
  background-color: #f3be59 !important;
}

.form-check-input:focus {
  border-color: #f3be59;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(243, 190, 89, 0.25);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #f3be59;
}

.rightTI {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 22s linear infinite;
}
.rightTI:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    text-indent: 100%;
  }
  100% {
    text-indent: -100%;
  }
}

.desk-marquee {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 136.9%;
  color: #ffffff;
}

.header-banner {
  margin-top: -2.5%;
  z-index: 0;
}

.header-top {
  z-index: 1500;
  position: relative;
}

.blinking {
  animation: blinkingText 1s infinite;
  font-family: futura;
  font-style: italic;
  width: 10%;
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 10px;
  background-color: #fc030b;
  border-radius: 10px;
  padding: 4px;
}

@keyframes blinkingText {
   
  0% {
      opacity: 0;
  }
  50% {
      opacity: .5;
  }
  100% {
      opacity: 1;
  }
}
