/* Language */
.na_select_lang {
  position: absolute;
  box-shadow: 2px -3px 11px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background: rgba(17, 17, 17, 0.4);
  border: 2px solid #ffba6e;
  border-radius: 9px 9px 0px 0px;
}

.na_select_lang_active {
  background: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.na_lang_options_div {
  /* visibility: hidden; */
  height: 0px;
  margin-bottom: 5px;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: #ffffff;
  border-radius: 8px;
  opacity: 0;
}

.na_lang_options_div_active {
  height: unset;
  width: unset;
  box-shadow: 0px 2.77818px 2.77818px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
  transition: all 200ms ease-in-out;
  opacity: 1;
}

.na_lang_options {
  display: flex;
}

.na_lang_option {
  flex: 1;
  margin: auto;
}
